import React from 'react';
import Typography from 'material-ui/Typography';
import { getCompanyName } from './util';


class Footer extends React.Component {

	goToPage = (url) => {
		if(this.context && this.context.router && this.context.router.history){
			this.context.router.history.push(url);
		}
	}

	render() {

		return (
			<div className="main-footer">
				<div className="main-container" style={{padding:"24px 0", textAlign: "center"}}>
					{/* <Grid container  spacing={16}>
						<Grid item lg={3} sm={6} xs={12}>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="title" color="secondary" align="left">
										ABOUT GREENSTONE
									</Typography>
									<Typography variant="body2" color="inherit">
									<p>GreenStone is a web-based build cost calculator designed for Builders, Architects and Homeowners.</p>
									<p>The application uses a simple & straightforward data entry system to produce output which details all aspects of your build. Our aim is to help our users save time and provide an honest & professional method of producing quotes.</p>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item lg={3} sm={6} xs={12}>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="title" color="secondary" align="left">COMPANY</Typography>
									<Typography variant="body2" color="inherit">
										<p>GreenStone's format can help all of its users save time and money. Additionally, Builders can gain a competitive advantage and generate new custom.</p>
										<p>It also allows Architects and Developers to compare quotes easily, giving them an informed choice when awarding contracts.</p>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item lg={3} sm={6} xs={12}>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="title" color="secondary" align="left">GREENSTONE OFFICE</Typography>
									<Typography  variant="body2" color="inherit">
										<p>
											<span style={{color:"silver", display:"inline-block", verticalAlign:"top", marginRight:"10px"}}><Room  /></span> 
											<span style={{display:"inline-block"}}><span style={{fontWeight:"bold"}}>Green Stone Enterprise</span>
											<br />304-305 Sidhraj Zori,<br/>Sargasan Chowkdi<br />Gandhinagar</span>
										</p>
										
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item lg={3} sm={6} xs={12}>
							<Grid container>
								<Grid item xs={12}>
									<Typography variant="title" color="secondary" align="left">BUSINESS HOURS</Typography>
									<Typography variant="body2" color="inherit">
										<p style={{borderBottom:"1px dotted rgba(192, 192, 192, 0.3)"}}>
											<span style={{width:"50%", display:"inline-block"}}>Monday - Friday:</span>
											<span style={{width:"50%", textAlign:"right", display:"inline-block"}}>9am - 6pm</span>
										</p>
										<p style={{borderBottom:"1px dotted rgba(192, 192, 192, 0.3)"}}>
											<span style={{width:"50%", display:"inline-block"}}>Saturday:</span>
											<span style={{width:"50%", textAlign:"right", display:"inline-block"}}>9am - 2pm</span>
										</p>
										<p style={{borderBottom:"1px dotted rgba(192, 192, 192, 0.3)"}}>
											<span style={{width:"50%", display:"inline-block"}}>Sunday:</span>
											<span style={{width:"50%", textAlign:"right", display:"inline-block"}}>Closed</span>
										</p>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid> */}					
					<a href="/aboutus" alt="" className="footer-link-btn">About ECS</a>
					<a href="/servicein" alt="" className="footer-link-btn">Service In</a>
					<a href="/blog" alt="" className="footer-link-btn">Blog</a>
					<a href="/careers" alt="" className="footer-link-btn">Careers</a>
					<a href="/joinus" alt="" className="footer-link-btn">Join as a Professional</a>
					<a href="/contactus" alt="" className="footer-link-btn">Contact Us</a>
				</div>
				<div className="footer-bottom">
					<div className="main-container">
						<Typography variant="body2" color="inherit">
							<p>
								<span style={{width:"50%", display:"inline-block"}}>&copy; {new Date().getFullYear()} {/* getCompanyName() */} Greenstone Enterprise Pvt. Ltd. All rights reserved</span>
								<span style={{width:"50%", textAlign:"right", display:"inline-block"}}>
									{/* <span className="rpad10">Home</span>
									<span className="rpad10">Services</span>
									<span className="rpad10">Partners</span> */}
									<a href="/privacy" className="no-link"><span className="rpad10">Privacy Policy</span></a>
									<a href="/terms" className="no-link"><span className="rpad10">Terms of Use</span></a>
									<a href="/shipping" className="no-link"><span className="rpad10">Shipping Policy</span></a>
									<a href="/cancellation" className="no-link"><span className="rpad10">Cancellation &amp; Refund</span></a>
									<a href="/return" className="no-link"><span className="rpad10">Return Policy</span></a>
									{/* <span className="rpad10">Login</span> */}
								</span>
							</p>
						</Typography>
					</div>
				</div>
			</div>
		);
	}
}


export default Footer;