import { combineReducers } from 'redux';
import mergeWith from 'lodash/merge';
import session from './session';
import service from './service';
import quotation from './quotation';
import cart from './cart';
import { AUTH_LOGOUT } from '../actions/session';

const defaultState = {
  services: []
};

const entities = (state = defaultState, action) => {
  if (action.response && action.response.entities) {
    return mergeWith({}, state, action.response.entities, (src, dest) => {
      // Deep merge arrays
      if (Array.isArray(src)) {
        return dest;
      }
    });
  }

  return state;
};

const appReducer = combineReducers({
  entities,
  session,
  service,
  quotation,
  cart,
});

const rootReducer = (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
}

export default rootReducer;
