import React from 'react';
import { connect } from 'react-redux'
import Typography from 'material-ui/Typography';
import {Carousel} from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import Grid from 'material-ui/Grid';
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames';
import grey from 'material-ui/colors/grey';


class OurClients extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
			carouselIndex: 0,
		};		
	}
	
	carouselChangeHandle = (index, element) => {
		if(index !== null && index !== undefined) {
            this.setState({ carouselIndex: index});
        }
	}
	
	handlePrev = () => {		
        this.setState({ carouselIndex: this.state.carouselIndex - 1});
	}
	
	handleNext = () => {
		this.setState({ carouselIndex: this.state.carouselIndex + 1});
	}

	render() {

		const { classes } = this.props;

		var slider = null;
		
		slider = (
			<Carousel
				showIndicators={false}
				showStatus={false}
				showThumbs={false}
				autoPlay={false}
				infiniteLoop={true}
				interval={3000}
				showArrows={false}
				centerMode 
				centerSlidePercentage={20}
				stopOnHover={false}>

				<div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>RS</span>
							<span className={classes.clients_name}>R S Corporation</span>
						</Typography>
					</div>
				</div>
				<div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>BP</span>
							<span className={classes.clients_name}>Bonny Polymers Pvt Ltd</span>
						</Typography>
					</div>
				</div>
				<div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>SG</span>
							<span className={classes.clients_name}>Sanidhyaa Group</span>
						</Typography>
					</div>
				</div>
				{/* <div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>SI</span>
							<span className={classes.clients_name}>Sidhraj Infrastructure Pvt Ltd</span>
						</Typography>
					</div>
				</div> */
				/* <div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>PS</span>
							<span className={classes.clients_name}>The Property Shop</span>
						</Typography>
					</div>
				</div> */}
				<div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>SP</span>
							<span className={classes.clients_name}>Shopan Pratishthan</span>
						</Typography>
					</div>
				</div>
				<div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>SG</span>
							<span className={classes.clients_name}>Swastik Group</span>
						</Typography>
					</div>
				</div>
				<div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>BP</span>
							<span className={classes.clients_name}>Bony Polymers Pvt Ltd</span>
						</Typography>
					</div>
				</div>
				<div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>GI</span>
							<span className={classes.clients_name}>Gurudev Infrastructure Co.</span>
						</Typography>
					</div>
				</div>
				<div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>MP</span>
							<span className={classes.clients_name}>Mihir Prajapati</span>
						</Typography>
					</div>
				</div>
				<div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>SR</span>
							<span className={classes.clients_name}>Sanjay Rathod</span>
						</Typography>
					</div>
				</div>
				<div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>DO</span>
							<span className={classes.clients_name}>Dr. Dharmang Oza</span>
						</Typography>
					</div>
				</div>
				<div className={classNames(classes.clients_main, "clients_main")}>
					<div className={classes.clients_wrapper}>
						<Typography>
							<span className={classNames(classes.clients_icon, "clients_icon")}>BM</span>
							<span className={classes.clients_name}>Bharat Mistry</span>
						</Typography>
					</div>
				</div>
				
			</Carousel>
		);
					

		return (			
			<div ref={(ref) => this.ourClientsCarouselRef = ref} className="main-container home">
				<div style={{margin: "60px 0 20px 0", backgroundColor: "#fff", paddingBottom: "20px"}}>
					<div className={classes.heading}>						
						<Grid container spacing={16}>
							<Grid item xs={12} sm={5}></Grid>
							<Grid item xs={12} sm={2}>
								<Typography variant="headline" align="center" style={{paddingBottom: "10px", fontWeight: "bold"}}>Our Clients</Typography>
							</Grid>
							<Grid item xs={12} sm={5}></Grid>
						</Grid>
					</div>
					<div className="clients_carousel" style={{position: "relative", marginTop: "5px"}}>
						{slider}					
					</div>
				</div>
			</div>			
		);
	}
}

const styles = theme => ({
	heading: {
		margin: "25px 0 25px 0", 
		padding: "20px 30px 10px 30px",
		backgroundColor: "#efefef",
    	borderBottom: "1px dashed #777"
    },
	margin: {
	  margin: theme.spacing.unit,
	},
	cssRoot: {
	  color: theme.palette.getContrastText(grey[100]),
	  backgroundColor: grey[100],
	  '&:hover': {
		backgroundColor: "#F7D200",
	  },
	},	
	ccontent: {
		padding: theme.spacing.unit,
	},
	cmedia: {
		height: 140
	},	
	shadowcard: {
		
	},
	noshadowcard: {
		boxShadow: "none"
	},
	clients_main: {
		background: "#fff"
	},
	clients_wrapper: {

	},
	clients_icon: {
		color: "#777",
    	margin: "0 auto",
		width: "75px",
		height: "75px",
		padding: "27px 10px",
		fontSize: "34px",
		boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
		borderRadius: "50%",
    	backgroundColor: "#f9f9f9",
		'&:hover': {
			color: "#444",			
			backgroundColor: "#fff",
		},
		display: "block",
	},
	clients_name: {
		display: "block",
		margin: "0 auto",
		fontSize: "16px",
		padding: "10px",
		color: "#444",
	}
});

OurClients.propTypes = {
	classes: PropTypes.object.isRequired,
};
OurClients.contextTypes = {
    router: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
	session: state.session,
	projectType: state.quotation.projectType,
});

export default withStyles(styles)(connect(mapStateToProps)(OurClients));