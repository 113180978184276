import { normalize, schema } from 'normalizr';

const serviceSchema = new schema.Entity('services', {}, {
  idAttribute: service => service.serviceId
});

export const Schemas = {
  SERVICE: serviceSchema,
  SERVICE_ARRAY: [serviceSchema],
};

export const CALL_API = 'Call API';

const callApi = (endpoint, schema, options) => {
  const API_ROOT = window.sessionStorage.getItem('RootAPI');
  return fetch(API_ROOT + endpoint, options)
    .then(response =>
      response.json().then(json => {
        if (!response.ok) {
          return Promise.reject(json);
        }

        if (schema) {
          return normalize(json, schema);
        }
        else {
          return json;
        }

      })
    );
};

export default store => next => action => {
  const callAPI = action[CALL_API];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { endpoint, schema, types, method, body } = callAPI;

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }
  if (!method) {
    throw new Error('Specify a method for api call.');
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }
  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const options = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'//,
      //'Authorization': 'Bearer ' + window.sessionStorage.getItem('APIKey')
    },
    method
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const actionWith = data => {
    const finalAction = { ...action, ...data };
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }));

  return callApi(endpoint, schema, options).then(
    response => next(actionWith({
      response,
      type: successType
    })),
    error => next(actionWith({
      type: failureType,
      error: error.ExceptionMessage || error.ErrorMessage || (error[0] && error[0].ErrorMessage) || 'An error has occurred.'
    }))
  );
}
