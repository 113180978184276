//import union from 'lodash/union'
import {
  SERVICES_REQUEST,
  SERVICES_SUCCESS,
  SERVICES_FAILURE,
  RECOM_SUBSERVICES_REQUEST,
  RECOM_SUBSERVICES_SUCCESS,
  RECOM_SUBSERVICES_FAILURE,
  BRANDS_REQUEST,
  BRANDS_SUCCESS,
  BRANDS_FAILURE,
  PRODUCT_TYPES_REQUEST,
  PRODUCT_TYPES_SUCCESS,
  PRODUCT_TYPES_FAILURE,
  PAYMENT_OPTIONS_REQUEST,
  PAYMENT_OPTIONS_SUCCESS,
  PAYMENT_OPTIONS_FAILURE,
  OFFERS_REQUEST,
  OFFERS_SUCCESS,
  OFFERS_FAILURE,
  PRODUCT_SIZES_REQUEST,
  PRODUCT_SIZES_SUCCESS,
  PRODUCT_SIZES_FAILURE,
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_FAILURE,
  PRODUCT_DETAIL_REQUEST,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_DETAIL_FAILURE,
  PROJECT_TYPES_REQUEST,
  PROJECT_TYPES_SUCCESS,
  PROJECT_TYPES_FAILURE,
  PROJECT_TYPE_DETAIL_REQUEST,
  PROJECT_TYPE_DETAIL_SUCCESS,
  PROJECT_TYPE_DETAIL_FAILURE,
  RESET_PROJECT_TYPE_DETAIL_SUCCESS,
  VENDOR_REGISTER_REQUEST, 
  VENDOR_REGISTER_SUCCESS, 
  VENDOR_REGISTER_FAILURE,
  SERVICE_AREA_REQUEST, 
  SERVICE_AREA_SUCCESS, 
  SERVICE_AREA_FAILURE,
  CATEGORIES_REQUEST, 
  CATEGORIES_SUCCESS, 
  CATEGORIES_FAILURE

} from '../actions/service';

export default (state = {
  isFetching: false,
  isRecomFetching: false,
  isBrandsFetching: false,
  isProductTypesFetching: false,
  isPaymentOptionsFetching: false,
  isOffersFetching: false,
  isProductSizesFetching: false,
  isProductsFetching: false,
  isProjectTypesFetching: false,
  isProjectTypeDetailFetching: false,
  isCategoriesFetching: false,
  error: undefined,
  services: [],
  recommendedSubServices: [],
  brands: [],
  productTypes: [],
  paymentOptions: [],
  offers: [],
  productSizes: [],
  projectTypes: [],
  categories: [],
  projectTypeDetail: {},
  subServiceProducts: [],
  subserviceDetail: {},
  isVendorRegistering: false,
  isVendorRegistered: false,
  isServiceAreaFetching: false,
  serviceArea: [],

}, action) => {
  switch (action.type) {
    case SERVICES_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case SERVICES_SUCCESS:
      return {
        ...state,
        isFetching: false,
        services: action.response.result,
        error: undefined
      }
    case SERVICES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case RECOM_SUBSERVICES_REQUEST:
      return {
        ...state,
        isRecomFetching: true
      }
    case RECOM_SUBSERVICES_SUCCESS:
      return {
        ...state,
        isRecomFetching: false,
        recommendedSubServices: action.response || [],
        error: undefined
      }
    case RECOM_SUBSERVICES_FAILURE:
      return {
        ...state,
        isRecomFetching: false,
        error: action.error
      }
    case BRANDS_REQUEST:
      return {
        ...state,
        isBrandsFetching: true
      }
    case BRANDS_SUCCESS:
      return {
        ...state,
        isBrandsFetching: false,
        //brands: action.response.result.lst,
        brands: action.response || [],        
        error: undefined
      }
    case BRANDS_FAILURE:
      return {
        ...state,
        isBrandsFetching: false,
        error: action.error
      }
    case PRODUCT_TYPES_REQUEST:
      return {
        ...state,
        isProductTypesFetching: true
      }
    case PRODUCT_TYPES_SUCCESS:
      return {
        ...state,
        isProductTypesFetching: false,
        //productTypes: action.response.result.lst,
        productTypes: action.response || [],
        error: undefined
      }
    case PRODUCT_TYPES_FAILURE:
      return {
        ...state,
        isProductTypesFetching: false,
        error: action.error
      }
    case PAYMENT_OPTIONS_REQUEST:
      return {
        ...state,
        isPaymentOptionFetching: true
      }
    case PAYMENT_OPTIONS_SUCCESS:
      return {
        ...state,
        isPaymentOptionFetching: false,
        //paymentOptions: action.response.result.lst,
        paymentOptions: action.response || [],
        error: undefined
      }
    case PAYMENT_OPTIONS_FAILURE:
      return {
        ...state,
        isPaymentOptionFetching: false,
        error: action.error
      }
    case OFFERS_REQUEST:
      return {
        ...state,
        isOffersFetching: true
      }
    case OFFERS_SUCCESS:
      return {
        ...state,
        isOffersFetching: false,
        offers: action.response.result.lst,
        error: undefined
      }
    case OFFERS_FAILURE:
      return {
        ...state,
        isOffersFetching: false,
        error: action.error
      }  
    case PRODUCT_SIZES_REQUEST:
      return {
        ...state,
        isProductSizesFetching: true
      }
    case PRODUCT_SIZES_SUCCESS:
      return {
        ...state,
        isProductSizesFetching: false,
        //productSizes: action.response.result.lst,
        productSizes: action.response || [],
        error: undefined
      }
    case PRODUCT_SIZES_FAILURE:
      return {
        ...state,
        isProductSizesFetching: false,
        error: action.error
      }    
    case PRODUCTS_REQUEST:
      return {
        ...state,
        isProductsFetching: true
      }
    case PRODUCTS_SUCCESS:
      return {
        ...state,
        isProductsFetching: false,
        //subServiceProducts: action.response.result.lst,
        subServiceProducts: action.response || [],
        error: undefined
      }
    case PRODUCTS_FAILURE:
      return {
        ...state,
        isProductsFetching: false,
        error: action.error
      }
    case PRODUCT_DETAIL_REQUEST:
      return {
        ...state,
        isFetching: true
      }
    case PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        isFetching: false,
        //productDetail: action.response.result.lst,
        productDetail: action.response || {},
        error: undefined
      }
    case PRODUCT_DETAIL_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    case PROJECT_TYPES_REQUEST:
      return {
        ...state,
        isProjectTypesFetching: true
      }
    case PROJECT_TYPES_SUCCESS:
      return {
        ...state,
        isProjectTypesFetching: false,
        //projectTypes: action.response.result.lst,
        projectTypes: action.response || [],
        error: undefined
      }
    case PROJECT_TYPES_FAILURE:
      return {
        ...state,
        isProjectTypesFetching: false,
        error: action.error
      }
    case PROJECT_TYPE_DETAIL_REQUEST:
      return {
        ...state,
        isProjectTypeDetailFetching: true
      }
    case PROJECT_TYPE_DETAIL_SUCCESS:
      return {
        ...state,
        isProjectTypeDetailFetching: false,
        //projectTypeDetail: action.response.result.lst,
        projectTypeDetail: action.response || {},
        error: undefined
      }
    case PROJECT_TYPE_DETAIL_FAILURE:
      return {
        ...state,
        isProjectTypeDetailFetching: false,
        error: action.error
      }
    case RESET_PROJECT_TYPE_DETAIL_SUCCESS:
      return {
        ...state,
        projectTypeDetail: {}
      }
    case VENDOR_REGISTER_REQUEST:
      return {
        ...state,
        isVendorRegistering: true,
        isVendorRegistered: false
      }
    case VENDOR_REGISTER_SUCCESS:
      return {
        ...state,
        isVendorRegistering: false,
        isVendorRegistered: true,
        error: undefined
      }
    case VENDOR_REGISTER_FAILURE:
      return {
        ...state,
        isVendorRegistering: false,
        isVendorRegistered: false,
        error: action.error
      }
    case SERVICE_AREA_REQUEST:
      return {
        ...state,
        isServiceAreaFetching: true
      }
    case SERVICE_AREA_SUCCESS:
      return {
        ...state,
        isServiceAreaFetching: false,
        serviceArea: action.response,
        error: undefined
      }
    case SERVICE_AREA_FAILURE:
      return {
        ...state,
        isServiceAreaFetching: false,
        error: action.error
      }
    case CATEGORIES_REQUEST:
      return {
        ...state,
        isCategoriesFetching: true
      }
    case CATEGORIES_SUCCESS:
      return {
        ...state,
        isCategoriesFetching: false,
        categories: action.response || [],        
        error: undefined
      }
    case CATEGORIES_FAILURE:
      return {
        ...state,
        isCategoriesFetching: false,
        error: action.error
      }
    default:
      return state;
  }
}