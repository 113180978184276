import { CALL_API/* , Schemas */ } from '../store/api';

export const CART_SET_SUCCESS = 'CART_SET_SUCCESS';
export const CART_ADD_PRODUCT_SUCCESS = 'CART_ADD_PRODUCT_SUCCESS';
export const CART_REMOVE_PRODUCT_SUCCESS = 'CART_REMOVE_PRODUCT_SUCCESS';
export const CART_UPDATE_PRODUCT_SUCCESS = 'CART_UPDATE_PRODUCT_SUCCESS';
export const CART_CLEAR_SUCCESS = 'CART_CLEAR_SUCCESS';
export const CART_UPDATE_CUST_INFO_SUCCESS = 'CART_UPDATE_CUST_INFO_SUCCESS';

export const CART_SUBMIT_REQUEST = 'CART_SUBMIT_REQUEST';
export const CART_SUBMIT_SUCCESS = 'CART_SUBMIT_SUCCESS';
export const CART_SUBMIT_FAILURE = 'CART_SUBMIT_FAILURE';

export const setCart = (content) => (dispatch, getState) => {

    return dispatch({
        type: CART_SET_SUCCESS,
        content: content,
    });
};

export const addProduct = (product) => (dispatch, getState) => {

    return dispatch({
        type: CART_ADD_PRODUCT_SUCCESS,
        product: product,
    });
};

export const updateProduct = (product) => (dispatch, getState) => {

    return dispatch({
        type: CART_UPDATE_PRODUCT_SUCCESS,
        product: product,
    });
};

export const removeProduct = (id) => (dispatch, getState) => {

    return dispatch({
        type: CART_REMOVE_PRODUCT_SUCCESS,
        id: id,
    });
};

export const clearCart = () => (dispatch, getState) => {

    return dispatch({
        type: CART_CLEAR_SUCCESS,
    });
};

export const submitCart = (body) => ({
    [CALL_API]: {
        types: [CART_SUBMIT_REQUEST, CART_SUBMIT_SUCCESS, CART_SUBMIT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'order/addclientandorder'
    }
});

export const updateCustomerInfo = (content) => (dispatch, getState) => {

    return dispatch({
        type: CART_UPDATE_CUST_INFO_SUCCESS,
        content: content,
    });
};