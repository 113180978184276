import React from 'react';
import Typography from 'material-ui/Typography';


class Loader extends React.Component {

	render() {

		var style = {};

		if(this.props.size && this.props.size >= 5 && this.props.size <= 18){
			style.width = style.height = this.props.size;
		}
		if(this.props.color){
			style.background = this.props.color;
		}

		var msg = null;
		if(this.props.text && this.props.text !== ""){
			msg = (
				<Typography 
					className="lds-text" 
					style={this.props.textColor && this.props.textColor ? {color: this.props.textColor} : {} }>
					{this.props.text}
				</Typography>
			);
		}

		return (
			<span>
				<span className="lds-ellipsis">
					<span style={style}></span>
					<span style={style}></span>
					<span style={style}></span>
					<span style={style}></span>
				</span>
				{msg}
			</span>
		);
	}
}

export default Loader;
