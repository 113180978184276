import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from 'material-ui/styles';
import Banner1 from '../img/banner1.jpg';
import Banner2 from '../img/banner2.jpg';
import Banner3 from '../img/banner3.jpg';
import Banner4 from '../img/banner4.jpg';
import Banner5 from '../img/banner5.jpg';
import TechnicalImage from '../img/manpower.jpg';
import LabourImage from '../img/labour.jpg';
import MachineryImage from '../img/machinery.jpg';
import MaterialImage from '../img/material.jpg';
import InvestorImage from '../img/investor.jpg';
import PropTypes from 'prop-types';
import {Carousel} from 'react-responsive-carousel';
import Typography from 'material-ui/Typography';
import Button from 'material-ui/Button';
import Card, {CardActions, CardContent, CardMedia} from 'material-ui/Card';
import CardActionArea from 'material-ui/Card';
import ProjectTypeDialog from './projecttypedialog';


class HomeCarousel extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired
    }

    constructor(props) {
        super(props);
        this.state = {
            loadCarousel: false,
            openDialog: false,
            dUrl: null
        };
    }

    componentDidMount(){
        if (!this.state.loadCarousel) {
            if(this.homeCarouselRef) {
                this.setState({loadCarousel: true});
            }
        }
    }

    componentWillUnmount() {
        if (this.homeCarouselRef) {
            this.setState({loadCarousel: false});
        }
    }

    goToServices = (id, name) => {
        if(!this.props.projectType || this.props.projectType === ""){
            this.setState({openDialog: true, dUrl: "/service/" + name});
        }
        else{
            this.props.history.push("/service/" + name);
        }
    }

    onDialogClose = (dUrl) => {
        //console.log('onDialogClose');
        //console.log('dUrl: ' + dUrl);
        if(dUrl && dUrl !== ""){
            this.props.history.push(dUrl);
        }
        else{
            this.setState({openDialog: false, dUrl: null});
        }
    }

    render() {

        const { classes } = this.props;

        var slider = null;
        if(this.state.loadCarousel) {
            slider = (
                <Carousel
                    showIndicators={true}
                    showStatus={false}
                    showThumbs={false}
                    autoPlay={true}
                    stopOnHover={true}
                    interval={5000}
                    emulateTouch={true}
                    infiniteLoop={true}>
                    <div>
                        <img src={Banner1} title="ecs" alt=""/>
                        <div className="banner-bg-effect"></div>
                    </div>
                    <div>
                        <img src={Banner2} title="ecs" alt=""/>
                        <div className="banner-bg-effect"></div>
                    </div>
                    <div>
                        <img src={Banner3} title="ecs" alt=""/>
                        <div className="banner-bg-effect"></div>
                    </div>
                    <div>
                        <img src={Banner4} title="ecs" alt=""/>
                        <div className="banner-bg-effect"></div>
                    </div>
                    <div>
                        <img src={Banner5} title="ecs" alt=""/>
                        <div className="banner-bg-effect"></div>
                    </div>
                </Carousel>
            );
        }

        return (

            <div className="home_slider" ref={(ref) => this.homeCarouselRef = ref}>
                {slider}  
                <div className={classes.cardWrapperBg}></div>  
                <div className={classes.cardWrapper}>
                    <Card className={classes.card}>                        
                        <CardActionArea square={true}>
                            <CardMedia
                                component="img"
                                className={classes.media}
                                height="100"
                                image={TechnicalImage}
                                title="Technical"
                            />
                            <CardContent className={classes.btArea}>
                                <Typography gutterBottom variant="headline" component="h2">
                                    Technical
                                </Typography>
                                <Typography component="p" align="justify">
                                    We outsourced all kind of technical professionals for different construction projects.
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.bbArea}>
                                <Button size="small" className={classes.cardBtn} onClick={(p) => {this.goToServices(1, 'Technical')}}>
                                    Learn More
                                </Button>
                            </CardActions>
                        </CardActionArea>                        
                    </Card>
                    <Card className={classes.card}>                        
                        <CardActionArea square={true}>
                            <CardMedia
                                component="img"
                                className={classes.media}
                                height="120"
                                image={LabourImage}
                                title="Labour & Contractor"
                            />
                            <CardContent className={classes.btArea}>
                                <Typography gutterBottom variant="headline" component="h2">
                                    Labour
                                </Typography>
                                <Typography component="p" align="justify">
                                    We outsourced all kind of skilled and Non skilled labour as per your requirement.
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.bbArea}>
                                <Button size="small" className={classes.cardBtn} onClick={(p) => {this.goToServices(2, 'Labour or Contractor')}}>
                                    Learn More
                                </Button>
                            </CardActions>
                        </CardActionArea>                        
                    </Card>
                    <Card className={classes.card}>                        
                        <CardActionArea square={true}>
                            <CardMedia
                                component="img"
                                className={classes.media}
                                height="140"
                                image={MachineryImage}
                                title="Machinery"
                            />
                            <CardContent className={classes.btArea}>
                                <Typography gutterBottom variant="headline" component="h2">
                                    Machinery
                                </Typography>
                                <Typography component="p" align="justify">
                                    We provide all kind of construction machinery on days rental or quantity measurement unit rate.
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.bbArea}>
                                <Button size="small" className={classes.cardBtn} onClick={(p) => {this.goToServices(3, 'Machinery')}}>
                                    Learn More
                                </Button>
                            </CardActions>
                        </CardActionArea>                        
                    </Card>
                    <Card className={classes.card}>                        
                        <CardActionArea square={true}>
                            <CardMedia
                                component="img"
                                className={classes.media}
                                height="120"
                                image={MaterialImage}
                                title="Material"
                            />
                            <CardContent className={classes.btArea}>
                                <Typography gutterBottom variant="headline" component="h2">
                                    Material
                                </Typography>
                                <Typography component="p" align="justify">
                                    We provide all kind of construction material at lowest price at your work place.
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.bbArea}>
                                <Button size="small" className={classes.cardBtn} onClick={(p) => {this.goToServices(4, 'Material')}}>
                                    Learn More
                                </Button>
                            </CardActions>
                        </CardActionArea>                          
                    </Card>
                    <Card className={classes.card}>                        
                        <CardActionArea square={true}>
                            <CardMedia
                                component="img"
                                className={classes.media}
                                height="100"
                                image={InvestorImage}
                                title="Investor"
                            />
                            <CardContent className={classes.btArea}>
                                <Typography gutterBottom variant="headline" component="h2">
                                    Investor
                                </Typography>
                                <Typography component="p" align="justify">
                                    We provide home loan, project loan, credit limit, term loan, loan against property and refunding facility.
                                </Typography>
                            </CardContent>
                            <CardActions className={classes.bbArea}>
                                <Button size="small" className={classes.cardBtn} onClick={(p) => {this.goToServices(5, 'Investor')}}>
                                    Learn More
                                </Button>
                            </CardActions>
                        </CardActionArea>                              
                    </Card>
                </div>
                <ProjectTypeDialog open={this.state.openDialog} onClose={this.onDialogClose} dUrl={this.state.dUrl} />
            </div>
        );
    }
}

const styles = theme => ({
    cardWrapperBg: {
        height: "120px", 
        backgroundColor: "#363435"
    },
    cardWrapper: {
        display: "block", 
        width: "100%", 
        textAlign: "center",
        marginTop: "-110px",
    },
    card: {
        maxWidth: 250,
        boxShadow: "none",        
        display: "inline-block",
        verticalAlign: "bottom",
        textAlign: "left",
        borderRadius: "0",        
    },
    media: {
        objectFit: 'cover',
    },
    actionArea: {
        
    },
    btArea: {
        borderTop: "1px solid #eee",
        borderLeft: "1px solid #eee",
        borderRight: "1px solid #eee",
        height: "155px"
    },
    bbArea: {
        borderBottom: "1px solid #eee",
        borderLeft: "1px solid #eee",
        borderRight: "1px solid #eee",
        display: "block",
        textAlign: "center",
    },
    cardBtn: {
        color: "#000",
        backgroundColor: "#ddd",

    }
});

HomeCarousel.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => ({
	session: state.session,
	projectType: state.quotation.projectType,
});

export default withStyles(styles)(connect(mapStateToProps)(HomeCarousel));