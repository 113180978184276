import React from 'react';
import BuilderImg from '../img/builder.jpeg';
import ArchitectImg from '../img/architect.jpg';
import SelfBuilderImg from '../img/selfbuilder.jpg';
import HomeOwnerImg from '../img/homeowner.jpg';
import Grid from 'material-ui/Grid';
import Typography from 'material-ui/Typography';
import Button from 'material-ui/Button';
import Card, { CardContent, CardMedia } from 'material-ui/Card';
import { getCompanyName } from './util';


class HowToUse extends React.Component {

	findOutMore = (name) => {
        console.log(name);
    }

	render() {

		return (
			<div className="main-container home">
				<div style={{border: "1px solid #F7D200", background: "#fff"}}>
					<Grid container>
						<Grid item xs={12} style={{padding: "20px 30px 10px 30px"}}>
							<Typography variant="headline">Choose how you want to use {getCompanyName()}:</Typography>
						</Grid>
						<Grid item xs={12} style={{padding: "5px 30px 30px 30px"}}>
							<Grid container spacing={40}>
								<Grid item sm={3} xs={12}>
									<Card>
										<CardMedia 
										style={{height: "150px"}}
										image={BuilderImg}						
										/>
										<CardContent>
											<Typography variant="headline" gutterBottom>
												I'm a Builder
											</Typography>
											<Typography>
												See how {getCompanyName()} works for builders.
											</Typography>
										</CardContent>
										<CardContent style={{textAlign: "center", marginTop: "30px"}}>
											<Button variant="raised" color="secondary" style={{width: "100%"}} onClick={(p) => {this.findOutMore('builder')}}>
												Find Out More
											</Button>
										</CardContent>
									</Card>
								</Grid>
								<Grid item sm={3} xs={12}>
									<Card>
										<CardMedia 
										style={{height: "150px"}}
										image={ArchitectImg}						
										/>
										<CardContent>
											<Typography variant="headline" gutterBottom>
												I'm a Government Contractor
											</Typography>
											<Typography>
												Find out how Government Contractor use {getCompanyName()}.
											</Typography>
										</CardContent>
										<CardContent style={{textAlign: "center"}}>
											<Button variant="raised" color="secondary" style={{width: "100%"}} onClick={(p) => {this.findOutMore('architect')}}>
												Find Out More
											</Button>
										</CardContent>
									</Card>
								</Grid>
								<Grid item sm={3} xs={12}>
									<Card>
										<CardMedia 
										style={{height: "150px"}}
										image={HomeOwnerImg}						
										/>
										<CardContent>
											<Typography variant="headline" gutterBottom>
												I'm an Industry Setup
											</Typography>
											<Typography>
												Industry Setup manage their project on {getCompanyName()}.
											</Typography>
										</CardContent>
										<CardContent style={{textAlign: "center"}}>
											<Button variant="raised" color="secondary" style={{width: "100%"}} onClick={(p) => {this.findOutMore('homeowner')}}>
												Find Out More
											</Button>
										</CardContent>
									</Card>
								</Grid>
								<Grid item sm={3} xs={12}>
									<Card>
										<CardMedia 
										style={{height: "150px"}}
										image={SelfBuilderImg}						
										/>
										<CardContent>
											<Typography variant="headline" gutterBottom>
												I'm an Individual
											</Typography>
											<Typography>
											Learn how Individuals use {getCompanyName()}.
											</Typography>
										</CardContent>
										<CardContent style={{textAlign: "center", marginTop: "30px"}}>
											<Button variant="raised" color="secondary" style={{width: "100%"}} onClick={(p) => {this.findOutMore('selfbuilder')}}>
												Find Out More
											</Button>
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</Grid>					
					</Grid>
				</div>
			</div>
		);
	}
}

export default HowToUse;
