import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Cookies from 'universal-cookie';
import configureStore from './store';
import { refreshAuthToken } from './actions/session';
import App from './containers/App';
import registerServiceWorker from './registerServiceWorker';

// Check for BaseAPI Env Variable
if (process.env.REACT_APP_BaseAPI !== undefined) {
    window.sessionStorage.setItem("BaseAPI", process.env.REACT_APP_BaseAPI);
}
else {    
    //window.sessionStorage.setItem("BaseAPI", "http://localhost:40715/api/");
    //window.sessionStorage.setItem("BaseAPI", "http://ecsapi.webmobe.net/api/");
    window.sessionStorage.setItem("BaseAPI", "http://ecswebapi.greenstoneent.com/api/");    
}
window.sessionStorage.setItem("RootAPI", window.sessionStorage.getItem("BaseAPI") + "");

const initialState = {};
const store = configureStore(initialState);

const cookies = new Cookies();
const authToken = cookies.get('authDetails');
if (authToken) {
    store.dispatch(refreshAuthToken());
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);


registerServiceWorker();
