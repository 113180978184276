import React from 'react';
import { connect } from 'react-redux';
import Typography from 'material-ui/Typography';
import {Carousel} from 'react-responsive-carousel';
import MainImg from '../img/main.jpg';
import ManpowerImg from '../img/manpower.jpg';
import LabourImg from '../img/labour.jpg';
import MachineryImg from '../img/machinery.jpg';
import MaterialImg from '../img/material.jpg';
import InvestorImg from '../img/investor.jpg';
import Button from 'material-ui/Button';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from 'material-ui/styles';
import grey from 'material-ui/colors/grey';
import KeyboardArrowRight from 'material-ui-icons/KeyboardArrowRight';
import KeyboardArrowLeft from 'material-ui-icons/KeyboardArrowLeft';
import Card, { CardContent, CardMedia } from 'material-ui/Card';
import { Link } from 'react-router-dom';
import ProjectTypeDialog from './projecttypedialog';


class HomeService extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
			carouselIndex: 0,
			openDialog: false,
			dUrl: null,
			subServiceId: null,
			subServiceName: null,
		};
		
		this.carouselChangeHandle = this.carouselChangeHandle.bind(this);
	}
	
	carouselChangeHandle(index, element){
		if(index !== null && index !== undefined) {
            this.setState({ carouselIndex: index});
        }
	}
	
	handlePrev = () => {		
        this.setState({ carouselIndex: this.state.carouselIndex - 1});
	}
	
	handleNext = () => {
		//this.setState({ carouselIndex: this.state.carouselIndex + ((this.state.carouselIndex === 0) ? 2 : 1)});
		this.setState({ carouselIndex: this.state.carouselIndex + 1});
	}

	goToLink = (event, dUrl, ssId, ssName) => {
		if(event) event.preventDefault();

		if(!this.props.projectType || this.props.projectType === ""){
            this.setState({openDialog: true, dUrl: dUrl, subServiceId: ssId, subServiceName: ssName});
        }
        else{
            if(this.context && this.context.router && this.context.router.history){
				this.context.router.history.push(dUrl);
			}
        }		
	}

	onDialogClose = (dUrl) => {
        if(dUrl && dUrl !== ""){
            if(this.context && this.context.router && this.context.router.history){
				this.context.router.history.push(dUrl);
			}
        }
        else{
            this.setState({openDialog: false, dUrl: null, subServiceId: null, subServiceName: null});
        }
    }
	
	render() {

		const { classes, service } = this.props;

		//var shadowClass = classes.noshadowcard;		
		var shadowClass = classes.shadowcard;	
		//var bColor = grey[400];	

		var slider = null;
		var showNextPrevButtons = false;
		var sPhoto = MainImg;

		var sUrl = "/";

		if(service && service.subServices){

			if(service.subServices.length > 4){
				showNextPrevButtons = true;
			}

			if(service.serviceId === 1){ 
				sPhoto = ManpowerImg;
				//bColor = blue[200];
			}
			else if(service.serviceId === 2){ 
				sPhoto = LabourImg;
				//bColor = lightGreen[400];
			}
			else if(service.serviceId === 3){ 
				sPhoto = MachineryImg;
				//bColor = amber[300];
			}
			else if(service.serviceId === 4){ 
				sPhoto = MaterialImg;
				//bColor = blueGrey[200];
			}
			else if(service.serviceId === 5){ 
				sPhoto = InvestorImg;
				//bColor = brown[200];
			}

			//if(service.serviceId === 1111) shadowClass = classes.shadowcard;

			if(service.serviceId !== 1111){
				sUrl = "/service/" + service.name;
			}

			slider = (
				<Carousel
					showIndicators={false}
					showStatus={false}
					showThumbs={false}
					infiniteLoop={true}
					showArrows={false}
					centerMode 
					centerSlidePercentage={25}
					selectedItem={this.state.carouselIndex}
					onChange={this.carouselChangeHandle}>

					{service.subServices.map(ss => (
						<div key={ss.subServiceId}>
							<Link to={"/subservice/" + service.name + "/" + ss.name} className="no-link" onClick={(event) => { this.goToLink(event, "/subservice/" + service.name + "/" + ss.name, ss.subServiceId, ss.name) }}>
								<Card className={shadowClass}>
									<CardMedia 
									className={classes.cmedia}
									image={ss.photo && ss.photo !== "" ? process.env.PUBLIC_URL + "/Images" + ss.photo : sPhoto}						
									/>
									<CardContent style={{padding: "8px"/* , backgroundColor: bColor */}}>
										<Typography variant="subheading" className="text-ellipses">
											{ss.name}
										</Typography>
									</CardContent>						
								</Card>
							</Link>
						</div>
					))}

					
					{/* <div>
						<img src={Banner1} alt=""/>

						<div style={{marginTop: "10px"}}>
							<Typography variant="subheading" align="center">
								Service 6 Title
							</Typography>
						</div>


						<Link to="/blank" className="no-link">
							<Card>
								<CardMedia 
								className={classes.cmedia}
								image={Banner1}					
								/>
								<CardContent style={{padding: "8px"}}>
									<Typography variant="subheading">
										Service 1 Title
									</Typography>
								</CardContent>						
							</Card>
						</Link>
					</div> */}
				</Carousel>
			);
		}			

		return (			
			<div ref={(ref) => this.homeServiceCarouselRef = ref} className="home-service-carousel">
				<div>
					<Typography variant="headline" gutterBottom style={{marginLeft: "5px"}}>
						<Link to={sUrl} className="no-link" onClick={(event) => { this.goToLink(event, sUrl) }}>
							<span>{service ? service.name + " Services" : ""}</span>
							<span style={{float: "right", position: "relative", color: "#1565C0"}}>								
								<span style={{paddingRight: "24px"}}>See All </span>
								<span style={{position: "absolute", top: "4px", right: "0px"}}><KeyboardArrowRight /></span>								
							</span>
						</Link>
					</Typography>
				</div>
				<div style={{position: "relative"}}>
					{showNextPrevButtons && 
						<div className="carousel-custom-button prev">
							<Button variant="fab" color="secondary" mini className={classNames(classes.margin, classes.cssRoot)} onClick={this.handlePrev}>
								<KeyboardArrowLeft />
							</Button>						
						</div>
					}
					{slider}
					{showNextPrevButtons &&
						<div className="carousel-custom-button next">
							<Button variant="fab" color="secondary" mini className={classNames(classes.margin, classes.cssRoot)} onClick={this.handleNext}>
								<KeyboardArrowRight />
							</Button>
						</div>
					}
				</div>
				<ProjectTypeDialog 
					open={this.state.openDialog} 
					onClose={this.onDialogClose} 
					dUrl={this.state.dUrl}
					subServiceId={this.state.subServiceId}
					subServiceName={this.state.subServiceName} />
			</div>			
		);
	}
}

const styles = theme => ({
	margin: {
	  margin: theme.spacing.unit,
	},
	cssRoot: {
	  color: theme.palette.getContrastText(grey[100]),
	  backgroundColor: grey[100],
	  '&:hover': {
		backgroundColor: "#F7D200",
	  },
	},	
	ccontent: {
		padding: theme.spacing.unit,
	},
	cmedia: {
		height: 140
	},	
	shadowcard: {
		
	},
	noshadowcard: {
		boxShadow: "none"
	},
});

HomeService.propTypes = {
	classes: PropTypes.object.isRequired,
};
HomeService.contextTypes = {
    router: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
	session: state.session,
	projectType: state.quotation.projectType,
});

export default withStyles(styles)(connect(mapStateToProps)(HomeService));