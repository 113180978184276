import React, { Component } from 'react';


class BlankPage extends Component {

	componentDidMount() {
		if (!this.props.showHeader) {
			this.props.onShowHideHeader(true);
		}
		if (!this.props.showFooter) {
			this.props.onShowHideFooter(true);
		}
	}

	render() {

		return (
			<div>
				<center><h2>Blank Page</h2></center>
			</div>
		);
	}
}


export default BlankPage;
