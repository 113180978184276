import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paper from 'material-ui/Paper';
import Typography from 'material-ui/Typography';
import { Carousel } from 'react-responsive-carousel';
import MainImg from '../img/main.jpg';
import Button from 'material-ui/Button';
import classNames from 'classnames';
import { withStyles } from 'material-ui/styles';
import grey from 'material-ui/colors/grey';
import KeyboardArrowRight from 'material-ui-icons/KeyboardArrowRight';
import KeyboardArrowLeft from 'material-ui-icons/KeyboardArrowLeft';
import Card, { CardContent, CardMedia } from 'material-ui/Card';
import { Link } from 'react-router-dom';
import ProjectTypeDialog from './projecttypedialog';


class RecommendedSubServices extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			carouselIndex: 0,
			openDialog: false,
            dUrl: null,
			subServiceId: null,
			subServiceName: null,
		};

		this.carouselChangeHandle = this.carouselChangeHandle.bind(this);
	}

	carouselChangeHandle(index, element) {
		if (index !== null && index !== undefined) {
			this.setState({ carouselIndex: index });
		}
	}

	handlePrev = () => {
		this.setState({ carouselIndex: this.state.carouselIndex - 1 });
	}

	handleNext = () => {
		//this.setState({ carouselIndex: this.state.carouselIndex + ((this.state.carouselIndex === 0) ? 2 : 1)});
		this.setState({ carouselIndex: this.state.carouselIndex + 1 });
	}

	goToLink = (event, dUrl, ssId, ssName) => {
		if(event) event.preventDefault();

		if(!this.props.projectType || this.props.projectType === ""){
            this.setState({openDialog: true, dUrl: dUrl, subServiceId: ssId, subServiceName: ssName});
        }
        else{
            if(this.context && this.context.router && this.context.router.history){
				this.context.router.history.push(dUrl);
			}
        }		
	}

	onDialogClose = (dUrl) => {
        if(dUrl && dUrl !== ""){
            if(this.context && this.context.router && this.context.router.history){
				this.context.router.history.push(dUrl);
			}
        }
        else{
            this.setState({openDialog: false, dUrl: null, subServiceId: null, subServiceName: null});
        }
    }

	render() {

		const { classes, subServices, showBg } = this.props;

		var slider = null;
		var showNextPrevButtons = false;
		var sPhoto = MainImg;

		var bgClass = classes.rootplain;
		var bgStyle = {};
		if (showBg){
			bgClass = classes.root;
			bgStyle = {marginBottom: "30px"};
		} 

		if (subServices && subServices.length > 0) {

			if (subServices.length > 4) {
				showNextPrevButtons = true;
			}

			slider = (
				<Carousel
					showIndicators={false}
					showStatus={false}
					showThumbs={false}
					infiniteLoop={true}
					showArrows={false}
					centerMode
					centerSlidePercentage={25}
					selectedItem={this.state.carouselIndex}
					onChange={this.carouselChangeHandle}>

					{subServices.map(ss => (
						<div key={ss.subServiceId}>
							<Link to={"/subservice/" + ss.serviceName + "/" + ss.name} className="no-link" onClick={(event) => { this.goToLink(event, "/subservice/" + ss.serviceName + "/" + ss.name, ss.subServiceId, ss.name) }}>
								<Card>
									<CardMedia
										className={classes.cmedia}
										image={ss.photo && ss.photo !== "" ? process.env.PUBLIC_URL + "/Images" + ss.photo : sPhoto}
									/>
									<CardContent style={{ padding: "8px"/* , backgroundColor: bColor */ }}>
										<Typography variant="subheading" className="text-ellipses">
											{ss.name}
										</Typography>
									</CardContent>
								</Card>
							</Link>
						</div>
					))}
				</Carousel>
			);
		}

		return (
			<div>
				<div className="main-container">
					<Paper className={bgClass}>
						<div className="home-service-carousel" style={bgStyle}>
							<div>
								<Typography variant="headline" gutterBottom style={{ marginLeft: "5px" }}>
									{"Recommended Services"}
								</Typography>
							</div>
							<div style={{ position: "relative" }}>
								{showNextPrevButtons &&
									<div className="carousel-custom-button prev">
										<Button variant="fab" color="secondary" mini className={classNames(classes.margin, classes.cssRoot)} onClick={this.handlePrev}>
											<KeyboardArrowLeft />
										</Button>
									</div>
								}
								{slider}
								{showNextPrevButtons &&
									<div className="carousel-custom-button next">
										<Button variant="fab" color="secondary" mini className={classNames(classes.margin, classes.cssRoot)} onClick={this.handleNext}>
											<KeyboardArrowRight />
										</Button>
									</div>
								}
							</div>
						</div>
					</Paper>
				</div>
				<ProjectTypeDialog 
					open={this.state.openDialog} 
					onClose={this.onDialogClose} 
					dUrl={this.state.dUrl}
					subServiceId={this.state.subServiceId}
					subServiceName={this.state.subServiceName} />
			</div>
		);
	}
}

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		padding: "1px 32px 1px"
	},
	rootplain: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		padding: "0",
		background: "transparent",
		boxShadow: "none",
	},
	margin: {
		margin: theme.spacing.unit,
	},
	cssRoot: {
		color: theme.palette.getContrastText(grey[100]),
		backgroundColor: grey[100],
		'&:hover': {
			backgroundColor: "#F7D200",
		},
	},
	cmedia: {
		height: 140
	},
});

RecommendedSubServices.propTypes = {
	classes: PropTypes.object.isRequired,
};
RecommendedSubServices.contextTypes = {
    router: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
	session: state.session,
	projectType: state.quotation.projectType,
});

export default withStyles(styles)(connect(mapStateToProps)(RecommendedSubServices));
