import React, { Component } from 'react';
import { BrowserRouter as Router, Route/* , Redirect */ } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import asyncComponent from "../components/AsyncComponent";
import NavBar from '../components/navbar';
import Footer from '../components/footer';
import HomePage from '../containers/HomePage';
import BlankPage from '../containers/BlankPage';
import Loader from '../components/loader';
import Snackbar from 'material-ui/Snackbar';
import IconButton from 'material-ui/IconButton';
import CloseIcon from 'material-ui-icons/Close';
//Carousel  css
import 'react-responsive-carousel/lib/styles/carousel.min.css';
//Index css
import '../css/index.css';
//Main css
import '../css/App.css';
//Loader css
import '../css/loader.css';
import { MuiThemeProvider, createMuiTheme } from 'material-ui/styles';
import CssBaseline from 'material-ui/CssBaseline';
import * as sessionActions from '../actions/session';

const AsyncSingleServicePage = asyncComponent(() => import("../containers/SingleServicePage"));
const AsyncServicesPage = asyncComponent(() => import("../containers/ServicesPage"));
const AsyncSubServicePage = asyncComponent(() => import("../containers/SubServicePage"));
const AsyncQuotationPage = asyncComponent(() => import("../containers/QuotationPage"));
const AsyncMaterialDetailPage = asyncComponent(() => import("../containers/MaterialDetailPage"));
const AsyncContactUsPage = asyncComponent(() => import("../containers/ContactUsPage"));
const AsyncAboutUsPage = asyncComponent(() => import("../containers/AboutUsPage"));
const AsyncCareersPage = asyncComponent(() => import("../containers/CareersPage"));
const AsyncJoinUsPage = asyncComponent(() => import("../containers/JoinUsPage"));
const AsyncBlogPage = asyncComponent(() => import("../containers/BlogPage"));
const AsyncServiceInPage = asyncComponent(() => import("../containers/ServiceInPage"));
const AsyncCartPage = asyncComponent(() => import("../containers/CartPage"));
const AsyncPrivacyPolicyPage = asyncComponent(() => import("../containers/PrivacyPolicyPage"));
const AsyncTermsOfUsePage = asyncComponent(() => import("../containers/TermsOfUsePage"));
const AsyncTrackingPage = asyncComponent(() => import("../containers/TrackingPage"));
const AsyncShippingPolicyPage = asyncComponent(() => import("../containers/ShippingPolicyPage"));
const AsyncCancellationAndRefundPage = asyncComponent(() => import("../containers/CancellationAndRefundPage"));
const AsyncReturnPolicyPage = asyncComponent(() => import("../containers/ReturnPolicyPage"));


const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#252525'
		},
		secondary: {
			main: '#F7D200'
		},
	},
	// typography: {
	// 	"fontFamily": "'Muli', sans-serif, Helvetica, Arial",
	// 	"fontSize": 16,
	// 	"fontWeightLight": 300,
	// 	"fontWeightRegular": 400,
	// 	"fontWeightMedium": 500
	// },
});

class App extends Component {

	constructor(props) {
		super(props);
		this.state = {
			showFooter: true,
			showHeader: true,
			showLoader: false,
			loaderMessage: ""
		};
		this.onShowHideHeader = this.onShowHideHeader.bind(this);
		this.onShowHideFooter = this.onShowHideFooter.bind(this);
	}

	onShowHideHeader(flag) {
		this.setState({ showHeader: flag });
	}

	onShowHideFooter(flag) {
		this.setState({ showFooter: flag });
	}

	showNotification = (message) => {
		this.setState({ loaderMessage: message, showLoader: true });
	}

	handleNotificationClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}	
		this.setState({ showLoader: false });
	};

	render() {

		//const { session } = this.props;

		var header = null;
		if (this.state.showHeader) {
			header = (
				<NavBar {...this.props} />
			);
		}

		var footer = null;
		if (this.state.showFooter) {
			footer = (
				<Footer {...this.props} />
			)
		}

		return (
			<MuiThemeProvider theme={theme}>
				<div className="App">
					<Snackbar
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						open={this.state.showLoader}
						autoHideDuration={6000}
						onClose={this.handleNotificationClose}
						SnackbarContentProps={{
							'aria-describedby': 'lmessage-id'
						}}
						message={
							<div id="lmessage-id" style={{ width: "200px", textAlign: "center" }}>
								{(this.state.loaderMessage && this.state.loaderMessage !== "") ?
									this.state.loaderMessage :
									<Loader color="#fff" textColor="#fff" text="Loading..." />
								}
							</div>
						}
						action={[
							<IconButton
							  key="close"
							  aria-label="Close"
							  color="inherit"
							  onClick={this.handleNotificationClose}>
								<CloseIcon />
							</IconButton>,
						  ]}
					/>
					<CssBaseline />

					<Router>
						<div className="main-top-wrapper">
							{header}

							<Route exact={true} path="/" render={(props) =>
								<HomePage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/blank" render={(props) =>
								<BlankPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/services" render={(props) =>
								<AsyncServicesPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/service/:serviceName" render={(props) =>
								<AsyncSingleServicePage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>
							<Route path="/subservice/:serviceName/:subServiceName" render={(props) =>
								<AsyncSubServicePage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>
							<Route path="/product-detail/:productId/:productName" render={(props) =>
								<AsyncMaterialDetailPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/quotation" render={(props) =>
								<AsyncQuotationPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/material/cement" render={(props) =>
								<AsyncMaterialDetailPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/contactus" render={(props) =>
								<AsyncContactUsPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/aboutus" render={(props) =>
								<AsyncAboutUsPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/careers" render={(props) =>
								<AsyncCareersPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/blog" render={(props) =>
								<AsyncBlogPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/joinus" render={(props) =>
								<AsyncJoinUsPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/servicein" render={(props) =>
								<AsyncServiceInPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/shoppingcart" render={(props) =>
								<AsyncCartPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/privacy" render={(props) =>
								<AsyncPrivacyPolicyPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/terms" render={(props) =>
								<AsyncTermsOfUsePage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/track/:token?" render={(props) =>
								<AsyncTrackingPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/shipping" render={(props) =>
								<AsyncShippingPolicyPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/cancellation" render={(props) =>
								<AsyncCancellationAndRefundPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

							<Route path="/return" render={(props) =>
								<AsyncReturnPolicyPage
									onShowHideFooter={this.onShowHideFooter}
									showFooter={this.state.showFooter}
									onShowHideHeader={this.onShowHideHeader}
									showHeader={this.state.showHeader}
									showNotification={this.showNotification}
									{...props} />
							}
							/>

						</div>
					</Router>
					{footer}
				</div>
			</MuiThemeProvider>
		);
	}
}

const mapStateToProps = (state, props) => {

	return {
		session: state.session
	}
};

const mapDispatchToProps = dispatch => ({
	sessionActions: bindActionCreators(sessionActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

// Redirect to the component only if the user is logged in, otherwise send to login page
/* const PrivateRoute = ({ component: Component, session, ...rest }) => (
	<Route {...rest} render={props => {

		const { isAuthenticating, isAuthenticated } = session;

		if (isAuthenticated === false && isAuthenticating === false) {

			return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />;
		}

		return <Component {...props} {...rest} />;

	}} />
) */