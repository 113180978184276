import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Dialog, {DialogTitle, DialogContent, DialogActions} from 'material-ui/Dialog';
import Radio, {RadioGroup} from 'material-ui/Radio';
import Button from 'material-ui/Button';
import {FormControlLabel} from 'material-ui/Form';
import Typography from 'material-ui/Typography';
import * as serviceActions from '../actions/service';
import * as quotationActions from '../actions/quotation';


class ProjectTypeDialog extends React.Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			open: this.props.open || false,
			projectType: this.props.projectType || null,
			errorMsg: null,
		};
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.projectType !== this.props.projectType) {
			this.setState({ projectType: nextProps.projectType });
		}
		if (nextProps.open !== this.props.open) {
			this.setState({ open: nextProps.open });
		}
		if(nextProps.projectTypeDetail && nextProps.projectTypeDetail.id &&
			JSON.stringify(this.props.projectTypeDetail) !== JSON.stringify(nextProps.projectTypeDetail) &&
			nextProps.subServiceId && nextProps.subServiceName){
			
			if(nextProps.projectTypeDetail.subServices && nextProps.projectTypeDetail.subServices.length > 0){

				var filteredSS = nextProps.projectTypeDetail.subServices.filter(ps => parseInt(ps.subServiceId,10) === parseInt(this.props.subServiceId,10));
				if(filteredSS && filteredSS.length > 0){
					if(this.props.onClose) this.props.onClose(this.props.dUrl);
				}
				else{
					var ptName = "selected project type";
					var filteredPT = this.props.projectTypesList.filter(t => t.value === this.props.projectType);
					if(filteredPT && filteredPT.length > 0){
						ptName = filteredPT[0].name;
					}
					this.props.quotationActions.resetProjectType();
					this.props.serviceActions.resetProjectTypeDetail();
					this.setState({ errorMsg: "We do not provide '" + this.props.subServiceName + "' service for " + ptName + ", please select a different project type." });
				}
			}
		}
	}

	handleCancel = () => {
		//this.setState({ projectType: null, errorMsg: null });
		if(this.props.onClose) this.props.onClose();
	};

	handleOk = () => {
		if(this.state.projectType && this.state.projectType !== ""){
			this.props.quotationActions.setProjectType(this.state.projectType);
			setTimeout(() => {
				if(this.props.projectTypesList && this.props.projectTypesList.length > 0){
					var filtered = this.props.projectTypesList.filter(t => t.value === this.state.projectType);
					if(filtered && filtered.length > 0){
						this.props.serviceActions.fetchProjectTypeDetail(filtered[0].id);
					}
				}
				if(!this.props.subServiceId && this.props.onClose){
					this.props.onClose(this.props.dUrl);	
				} 
			}, 100);
		}
		else{
			this.setState({ errorMsg: "Please select project type." });	
		}
	};

	handleChange = (event, value) => {
		this.setState({ projectType: value, errorMsg: null });
	};

	render() {
		const { classes, open } = this.props;

		var options = this.props.projectTypesList;

		return (
			<Dialog
				disableBackdropClick
				disableEscapeKeyDown
				maxWidth="xs"
				aria-labelledby="confirmation-dialog-title"
				classes={{
					paper: classes.dialog,
				}}
				open={open}>
				<DialogTitle id="confirmation-dialog-title">Project Type</DialogTitle>
				<DialogContent>				
					<RadioGroup
						aria-label="Project Type"
						name="projectType"
						value={this.state.projectType}
						onChange={this.handleChange}>
						{options.map((option, idx) => (
							<FormControlLabel value={option.value} key={option.id} control={<Radio />} 
								label={
									<span>
										<Typography variant="body1">
											{option.name}
										</Typography>
										<Typography variant="caption">
											{option.note}
										</Typography>
									</span>
								} />
						))}
					</RadioGroup>
					{this.state.errorMsg && this.state.errorMsg !== "" &&
					<Typography style={{color: "#C64A48"}} gutterBottom>
						{this.state.errorMsg}
					</Typography>
					}
				</DialogContent>
				<DialogActions>
					<Button onClick={this.handleCancel} color="primary">
						Cancel
			  		</Button>
					<Button onClick={this.handleOk} color="secondary" variant="raised">
						Ok
			  		</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3,
		overflowX: 'auto',
		padding: theme.spacing.unit * 3,
	},
	dialog: {
		width: '80%',
		maxHeight: 435,
	},
});

ProjectTypeDialog.propTypes = {
	classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => ({
	session: state.session,
	projectType: state.quotation.projectType,
	projectTypesList: state.service.projectTypes || [],
	projectTypeDetail: state.service.projectTypeDetail || {},
});

const mapDispatchToProps = dispatch => ({
	serviceActions: bindActionCreators(serviceActions, dispatch),
	quotationActions: bindActionCreators(quotationActions, dispatch)
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProjectTypeDialog));
