import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import api from './api';
import rootReducer from '../reducers';

let middleware = [thunk, api];

if (process.env.NODE_ENV !== 'production') {
    const logger = require('redux-logger');

    middleware = [...middleware, logger.createLogger({ collapsed: true })];
}

export default function configureStore(initialState) {
    const store = createStore(
        rootReducer,
        initialState,
        applyMiddleware(...middleware)
    );

    return store;
}
