//import union from 'lodash/union'
import {
  QUOTATION_UPDATE_SUCCESS,
  SELECT_PROJECT_TYPE_SUCCESS,
  RESET_PROJECT_TYPE_SUCCESS,
  QUOTATION_SUBMIT_REQUEST, 
  QUOTATION_SUBMIT_SUCCESS, 
  QUOTATION_SUBMIT_FAILURE

} from '../actions/quotation';

export default (state = {
  quotation: {},
  projectType: "",
  error: undefined,
  isQuotationSubmitting: false,
  isQuotationSubmitted: false,

}, action) => {
  switch (action.type) {
    case QUOTATION_UPDATE_SUCCESS:
      return {
        ...state,
        quotation: action.parameters,
        isQuotationSubmitting: false,
        isQuotationSubmitted: false,
      }
    case SELECT_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        projectType: action.projectType,
      }  
    case RESET_PROJECT_TYPE_SUCCESS:
      return {
        ...state,
        projectType: "",
      }
    case QUOTATION_SUBMIT_REQUEST:
      return {
        ...state,
        isQuotationSubmitting: true,
        isQuotationSubmitted: false,
      }
    case QUOTATION_SUBMIT_SUCCESS:
      return {
        ...state,
        isQuotationSubmitting: false,
        isQuotationSubmitted: true,
        error: undefined
      }  
    case QUOTATION_SUBMIT_FAILURE:
      return {
        ...state,
        isQuotationSubmitting: false,
        isQuotationSubmitted: false,
        error: action.error
      }
    default:
      return state;
  }
}