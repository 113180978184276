import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import Typography from 'material-ui/Typography';
import GSLogo from '../img/gs-logo.png';
import { Link } from 'react-router-dom';
import Button from 'material-ui/Button';
import PropTypes from 'prop-types';
import IconButton from 'material-ui/IconButton';
import Badge from 'material-ui/Badge';
import ShoppingCart from 'material-ui-icons/ShoppingCart';
import { getCompanyName } from './util';
import * as serviceActions from '../actions/service';
import * as cartActions from '../actions/cart';


class NavBar extends React.Component {

	componentDidMount(){

		var cart = window.sessionStorage.getItem("ShoppingCart");
		if(cart !== undefined && cart !== null){
			this.props.cartActions.setCart(JSON.parse(cart));
		}
		var cartCI = window.sessionStorage.getItem("ShoppingCartCustomerInfo");
		if(cartCI !== undefined && cartCI !== null){
			this.props.cartActions.updateCustomerInfo(JSON.parse(cartCI));
		}

		if(!this.props.servicesList || (this.props.servicesList && this.props.servicesList.length === 0)){
			this.props.serviceActions.fetchServices();
		}
		if(!this.props.projectTypesList || (this.props.projectTypesList && this.props.projectTypesList.length === 0)){
			this.props.serviceActions.fetchProjectTypes();
		}
		if(!this.props.serviceAreaList || (this.props.serviceAreaList && this.props.serviceAreaList.length === 0)){
			this.props.serviceActions.fetchServiceArea();
		}				
    }

	goToServices = () => {
		if(this.context && this.context.router && this.context.router.history){
			this.context.router.history.push("/services");
		}
	}
	
	goToPartners = () => {
        
	}

	goToJoinUs = () => {
		if(this.context && this.context.router && this.context.router.history){
			this.context.router.history.push("/joinus");
		}        
	}
	
	goToLogin = () => {
        
	}
	
	goToCart = () => {
        if(this.context && this.context.router && this.context.router.history){
			this.context.router.history.push("/shoppingcart");
		}
    }

	render() {

		return (
			<div style={{ flexGrow: 1 }}>				
				<AppBar position="fixed" style={{backgroundColor: "#fff"}}>					
					<Toolbar>									
						<Typography variant="title" color="inherit" style={{ flex: 1, marginLeft: "3px" }}>
							<Link to='/' style={{display: "inline-block", verticalAlign: "middle"}}>
								<img src={GSLogo} alt={getCompanyName()} />	
							</Link>	
							<span className='logo-subtitle'>
								A web base service provider for construction sector
							</span>						
						</Typography>
						<Button className="btn-menu-link" color="primary" onClick={this.goToServices}>
							Services
						</Button>
						<Button className="btn-menu-link" color="primary" onClick={this.goToPartners}>
							Partners
						</Button>
						<Button className="btn-menu-link" color="primary" onClick={this.goToJoinUs}>
							Join as a Professional
						</Button>
						<Button className="btn-menu-link" color="primary" onClick={this.goToLogin}>
							Login
						</Button>
						<IconButton className="btn-menu-link" onClick={this.goToCart}>
							{(this.props.cartItems && this.props.cartItems.length > 0) ?
								<Badge badgeContent={this.props.cartItems.length} color="secondary">
									<ShoppingCart />
								</Badge> :
								<ShoppingCart />
							}	
						</IconButton>
					</Toolbar>					
				</AppBar>
			</div>
		);
	}
}

NavBar.contextTypes = {
    router: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
    session: state.session,
	servicesList: state.service.services.map(id => state.entities.services[id]) || [],
	projectTypesList: state.service.projectTypes || [],
	serviceAreaList: state.service.serviceArea || [],
	cartItems: state.cart.products || [],
});

const mapDispatchToProps = dispatch => ({
	serviceActions: bindActionCreators(serviceActions, dispatch),
	cartActions: bindActionCreators(cartActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
