import { CALL_API/* , Schemas */ } from '../store/api';

export const QUOTATION_UPDATE_SUCCESS = 'QUOTATION_UPDATE_SUCCESS';
export const SELECT_PROJECT_TYPE_SUCCESS = 'SELECT_PROJECT_TYPE_SUCCESS';
export const RESET_PROJECT_TYPE_SUCCESS = 'RESET_PROJECT_TYPE_SUCCESS';

export const QUOTATION_SUBMIT_REQUEST = 'QUOTATION_SUBMIT_REQUEST';
export const QUOTATION_SUBMIT_SUCCESS = 'QUOTATION_SUBMIT_SUCCESS';
export const QUOTATION_SUBMIT_FAILURE = 'QUOTATION_SUBMIT_FAILURE';

export const updateQuotation = (parameters) => (dispatch, getState) => {

    return dispatch({
        type: QUOTATION_UPDATE_SUCCESS,
        parameters: parameters,
    });
};

export const setProjectType = (projectType) => (dispatch, getState) => {

    return dispatch({
        type: SELECT_PROJECT_TYPE_SUCCESS,
        projectType: projectType,
    });
};

export const resetProjectType = () => (dispatch, getState) => {

    return dispatch({
        type: RESET_PROJECT_TYPE_SUCCESS,
    });
};

export const submitQuotation = (body) => ({
    [CALL_API]: {
        types: [QUOTATION_SUBMIT_REQUEST, QUOTATION_SUBMIT_SUCCESS, QUOTATION_SUBMIT_FAILURE],
        method: 'POST',
        body: body,
        endpoint: 'quotation/addquotation'
    }
});