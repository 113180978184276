export function getFormatedCurrency(num) {
    if (isNaN(num)) return "";
    var n = Math.round(num);
    return "Rs." + n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getFormatedNumber(num) {    
    if (isNaN(num)) return "";
    var n = Math.round(num);
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function getFormatedDate(date, format) {
    var dt = new Date(date);
    var fDt = format.replace(/yyyy/g, dt.getFullYear()).replace(/mm/g, (dt.getMonth()+1)).replace(/dd/g, dt.getDate());
    return fDt;
}

export function getProjectTypes() {
    return [
        {value: "individual_project", text: "Individual Project"},
        {value: "commercial_project", text: "Commercial Project"},
        {value: "industrial_project", text: "Industrial Project"},
        {value: "government_project", text: "Government Project"},
    ];
}

export function getProjectTypeValue(projectTypeText) {
    var lst = getProjectTypes();
    lst = lst.filter(t => t.text === projectTypeText);
    return ((lst && lst.length > 0) ? lst[0].value : null);
}

export function getProjectTypeText(projectTypeValue) {
    var lst = getProjectTypes();
    lst = lst.filter(t => t.value === projectTypeValue);
    return ((lst && lst.length > 0) ? lst[0].text : null);
}

export function getConstBudgetPlans() {
    return [
        {value: "800", text: "Silver (800/sqft)"},
        {value: "1200", text: "Gold (1200/sqft)"},
        {value: "1600", text: "Platinum (1600/sqft)"},
    ];
}

export function getTransportServices() {
    return [
        {value: "Road", text: "Road"},
        {value: "Rail", text: "Rail"},
        {value: "Port", text: "Port"},
    ];
}

export function getCompanyName() {
    if (process.env.REACT_APP_CompanyName !== undefined && process.env.REACT_APP_CompanyName !== null &&
        process.env.REACT_APP_CompanyName !== "") {
        return process.env.REACT_APP_CompanyName;
    }

    return "Easy Construction Services";
}