import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import HomeService from './homeservice';
import RecommendedSubServices from './recommendedsubservices';
import * as serviceActions from '../actions/service';


class HomeServicesList extends React.Component {

	componentDidMount(){
        this.props.serviceActions.fetchRecommendedServices(0);
    }

	render() {

		const { servicesList, recommendedSubServices } = this.props;

		return (
			<div ref={(ref) => this.homeServicesRef = ref} className="main-container home">
				<div style={{marginTop: "40px"}}>
					{recommendedSubServices && recommendedSubServices.length > 0 &&
						<RecommendedSubServices subServices={recommendedSubServices} />
					}
					{servicesList.map((s,idx) => (
						<HomeService key={idx} service={s}  />
					))}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state, props) => ({
    session: state.session,
	servicesList: state.service.services.map(id => state.entities.services[id]) || [],
	recommendedSubServices: state.service.recommendedSubServices || []
});

const mapDispatchToProps = dispatch => ({
    serviceActions: bindActionCreators(serviceActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeServicesList);
