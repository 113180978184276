//import union from 'lodash/union'
import {
  CART_SET_SUCCESS,
  CART_ADD_PRODUCT_SUCCESS,
  CART_REMOVE_PRODUCT_SUCCESS,
  CART_UPDATE_PRODUCT_SUCCESS,
  CART_CLEAR_SUCCESS,
  CART_UPDATE_CUST_INFO_SUCCESS,
  CART_SUBMIT_REQUEST, 
  CART_SUBMIT_SUCCESS, 
  CART_SUBMIT_FAILURE

} from '../actions/cart';

export default (state = {
  products: [],
  customerInfo: {},
  isCartSubmitting: false,
  cartSubmission: {}

}, action) => {
  switch (action.type) {
    case CART_SET_SUCCESS:
      window.sessionStorage.setItem("ShoppingCart", JSON.stringify(action.content));

      return {
        ...state,
        products: action.content
      }
    case CART_UPDATE_CUST_INFO_SUCCESS:
      window.sessionStorage.setItem("ShoppingCartCustomerInfo", JSON.stringify(action.content));

      return {
        ...state,
        customerInfo: action.content
      }
    case CART_ADD_PRODUCT_SUCCESS:
      var prdA = state.products;
      var existFlag = false;
      prdA.map(p => {
        if(p.id === action.product.id){
          existFlag = true;
          p.qty = parseInt(p.qty,10) + parseInt(action.product.qty,10);
        }
        return null;
      });
      if(!existFlag){
        prdA = prdA.concat(action.product);
      }

      window.sessionStorage.setItem("ShoppingCart", JSON.stringify(prdA));

      return {
        ...state,
        products: prdA
      }
    case CART_REMOVE_PRODUCT_SUCCESS:
      var prdR = state.products.filter(p => p.id !== action.id);
      window.sessionStorage.setItem("ShoppingCart", JSON.stringify(prdR));

      return {
        ...state,
        products: prdR
      }  
    case CART_UPDATE_PRODUCT_SUCCESS:
      var prdU = state.products;
      prdU.map(p => {
        if(p.id === action.product.id){
          p = action.product;
        }
        return null;
      });

      window.sessionStorage.setItem("ShoppingCart", JSON.stringify(prdU));

      return {
        ...state,
        products: prdU
      }
    case CART_CLEAR_SUCCESS:
      window.sessionStorage.removeItem("ShoppingCart");
      window.sessionStorage.removeItem("ShoppingCartCustomerInfo");
      return {
        ...state,
        products: [],
        customerInfo: {}
      }

    case CART_SUBMIT_REQUEST:
      return {
        ...state,
        isCartSubmitting: true
      }
    case CART_SUBMIT_SUCCESS:
      return {
        ...state,
        isCartSubmitting: false,
        cartSubmission: action.response,
        error: undefined
      }
    case CART_SUBMIT_FAILURE:
      return {
        ...state,
        cartSubmission: false,
        error: action.error
      }

    default:
      return state;
  }
}