import React from 'react';
import { withStyles } from 'material-ui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Grid from 'material-ui/Grid';
import Typography from 'material-ui/Typography';
import HighQuality from 'material-ui-icons/HighQuality';
import AssignmentTurnedIn from 'material-ui-icons/AssignmentTurnedIn';
import DateRange from 'material-ui-icons/DateRange';
import ContactPhone from 'material-ui-icons/ContactPhone';
import Event from 'material-ui-icons/Event';
import CheckBox from 'material-ui-icons/CheckBox';


class WhyUs extends React.Component {

	findOutMore = (name) => {
        console.log(name);
    }

	render() {

		const { classes } = this.props;

		return (
			<div className="main-container home">
				<div style={{margin: "25px 0 20px 0", backgroundColor: "#fff"}}>
					<div className={classes.heading}>						
						<Grid container spacing={16}>
							<Grid item xs={12} sm={5}></Grid>
							<Grid item xs={12} sm={2}>
								<Typography variant="headline" align="center" style={{paddingBottom: "10px", fontWeight: "bold"}}>Why Us</Typography>
							</Grid>
							<Grid item xs={12} sm={5}></Grid>
						</Grid>
					</div>
					<div>
						<Grid container spacing={16}>
							<Grid item xs={12} sm={5}>
								<div>
									<div className="qtyRow first">
										<Grid container spacing={16}>
											<Grid item xs={10}>
												<Typography variant="body2" align="right" gutterBottom className={classes.qtyText}>High Quality & Trusted Professionals</Typography>
												<Typography align="right" className={classes.qtyDesc}>We provide only verified, background checked and high-quality professionals</Typography>
											</Grid>
											<Grid item xs={2}>
												<HighQuality className={classNames(classes.qtyIcon, "qtyIcon")} />
											</Grid>
										</Grid>
									</div>
									<div className="qtyRow">
										<Grid container spacing={16}>
											<Grid item xs={10}>
												<Typography variant="body2" align="right" gutterBottom className={classes.qtyText}>Matched to Your Needs</Typography>
												<Typography align="right" className={classes.qtyDesc}>We match you with the right professionals with the right budget</Typography>
											</Grid>
											<Grid item xs={2}>
												<AssignmentTurnedIn className={classNames(classes.qtyIcon, "qtyIcon")} />
											</Grid>
										</Grid>
									</div>
									<div className="qtyRow last">
										<Grid container spacing={16}>
											<Grid item xs={10}>
												<Typography variant="body2" align="right" gutterBottom className={classes.qtyText}>Hassle Free Service Delivery</Typography>
												<Typography align="right" className={classes.qtyDesc}>Super convenient, guaranteed service from booking to delivery</Typography>
											</Grid>
											<Grid item xs={2}>
												<DateRange className={classNames(classes.qtyIcon, "qtyIcon")} />
											</Grid>
										</Grid>
									</div>
								</div>
							</Grid>
							<Grid item xs={12} sm={2}>
								<Typography component="h2" variant="display4" className={classNames(classes.qtyMainText, "qtyMainText")}>E</Typography>
								<Typography component="h2" variant="display4" className={classNames(classes.qtyMainText, "qtyMainText")}>C</Typography>
								<Typography component="h2" variant="display4" className={classNames(classes.qtyMainText, "qtyMainText")}>S</Typography>
							</Grid>
							<Grid item xs={12} sm={5}>
								<div>
									<div className="qtyRow first">
										<Grid container spacing={16}>
											<Grid item xs={2}>
												<CheckBox className={classNames(classes.qtyIcon, "qtyIcon")} />
											</Grid>
											<Grid item xs={10}>
												<Typography variant="body2" gutterBottom className={classes.qtyText}>Material Test</Typography>
												<Typography className={classes.qtyDesc}>We provide best construction material with lab test report</Typography>
											</Grid>										
										</Grid>
									</div>
									<div className="qtyRow">
										<Grid container spacing={16}>
											<Grid item xs={2}>
												<Event className={classNames(classes.qtyIcon, "qtyIcon")} />
											</Grid>
											<Grid item xs={10}>
												<Typography variant="body2" gutterBottom className={classes.qtyText}>On Time delivery</Typography>
												<Typography className={classes.qtyDesc}>Speciality lies with on time delivery to provide satisfaction and make its worth realize</Typography>
											</Grid>										
										</Grid>
									</div>
									<div className="qtyRow last">
										<Grid container spacing={16}>
											<Grid item xs={2}>
												<ContactPhone className={classNames(classes.qtyIcon, "qtyIcon")} />
											</Grid>
											<Grid item xs={10}>
												<Typography variant="body2" gutterBottom className={classes.qtyText}>Online Support</Typography>
												<Typography className={classes.qtyDesc}>Dedicated resources always available to provide assistance</Typography>
											</Grid>										
										</Grid>
									</div>
								</div>
							</Grid>											
						</Grid>
					</div>
				</div>
			</div>
		);
	}
}

const styles = theme => ({
    heading: {
		marginBottom: "25px", 
		padding: "20px 30px 10px 30px",
		backgroundColor: "#efefef",
    	borderBottom: "1px dashed #777"
    },
    qtyText: {
		textTransform: "uppercase", 
		color: "#777",
		fontWeight: "bold"
    },
    qtyDesc: {
        color: "#555"
    },
    qtyIcon: {
		fontSize: "72px",
		padding: "10px",
		color: "#777",
		boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
		borderRadius: "50%",
		backgroundColor: "#f9f9f9",
		'&:hover': {
			color: "#444",			
			backgroundColor: "#fff"
		},
    },
    qtyMainText: {
		color: "#444",
		textAlign: "center",
		'&:hover': {
			color: "#000",
			/* boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)", */
		}
    },
});

WhyUs.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(WhyUs);
