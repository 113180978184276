import Cookies from 'universal-cookie';
import {
    AUTH_REQUEST,
    AUTH_FAILURE,
    AUTH_LOGIN_SUCCESS,
    AUTH_LOGOUT

} from '../actions/session';

let cookies;

const setSessionItems = resp => {

}

export default (state = {
    isAuthenticating: false,
    isAuthenticated: false,
    user: null,
    notificationMessage: null,
    error: undefined,
    emailError: undefined
}, action) => {
    switch (action.type) {
        case AUTH_REQUEST:
            return {
                ...state,
                isAuthenticating: true,
                error: undefined
            };
        case AUTH_LOGIN_SUCCESS:
            setSessionItems(action.response);

            cookies = new Cookies();
            cookies.set('authDetails', { Id: 1, Name: "GS" }, { path: '/' });

            return {
                ...state,
                isAuthenticating: false,
                isAuthenticated: true,
                user: { Id: 1, Name: "GS" },
                error: undefined
            };
        case AUTH_LOGOUT:

            cookies = new Cookies();
            cookies.remove('authDetails');

            return {
                ...state,
                isAuthenticated: false,
                user: null,
                error: undefined
            };
        case AUTH_FAILURE:
            return {
                ...state,
                isAuthenticating: false,
                error: action.error
            };
        default:
            return state;
    }
};
