import Cookies from 'universal-cookie';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_FAILURE = 'AUTH_FAILURE';

export const refreshAuthToken = id => (dispatch, getState) => {
    const cookies = new Cookies();
    const authToken = cookies.get('authDetails');

    if (!authToken) return dispatch(logout());

    return dispatch({
        type: AUTH_LOGIN_SUCCESS
    });
};

export const login = (body) => (dispatch, getState) => {

    if (body.Username !== "gs" || body.Password !== "gs12345") {
        return dispatch({
            type: AUTH_FAILURE,
            error: "Invalid Username or Password"
        });
    }

    return dispatch({
        type: AUTH_LOGIN_SUCCESS
    });
};

export const logout = () => (dispatch, getState) => {
    return dispatch({
        type: AUTH_LOGOUT
    });
};