import React, { Component } from 'react';
import HomeCarousel from '../components/homecarousel';
import HomeServicesList from '../components/homeserviceslist';
import HowToUse from '../components/howtouse';
import WhyUs from '../components/whyus';
import OurClients from '../components/ourclients';
import CustomersFeedback from '../components/customersfeedback';


class HomePage extends Component {

	componentDidMount() {
		if (!this.props.showHeader) {
			this.props.onShowHideHeader(true);
		}
		if (!this.props.showFooter) {
			this.props.onShowHideFooter(true);
		}
	}

	render() {
		return (
			<div>
				<div>
					<HomeCarousel history={this.props.history} />
				</div>
				<div>
					<HomeServicesList history={this.props.history} />
				</div>
				<div>
					<WhyUs history={this.props.history} />
				</div>				
				<div>
					<OurClients history={this.props.history} />
				</div>
				<div>
					<CustomersFeedback history={this.props.history} />
				</div>
				<div>
					<HowToUse history={this.props.history} />
				</div>
				<br/>
				<br/>
			</div>
		);
	}
}

export default HomePage;
