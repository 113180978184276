import React from 'react';
import { connect } from 'react-redux';
import Typography from 'material-ui/Typography';
import {Carousel} from 'react-responsive-carousel';
import PropTypes from 'prop-types';
import Grid from 'material-ui/Grid';
import { withStyles } from 'material-ui/styles';
import grey from 'material-ui/colors/grey';


class CustomersFeedback extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
			carouselIndex: 0,
		};		
	}
	
	carouselChangeHandle = (index, element) => {
		if(index !== null && index !== undefined) {
            this.setState({ carouselIndex: index});
        }
	}
	
	handlePrev = () => {		
        this.setState({ carouselIndex: this.state.carouselIndex - 1});
	}
	
	handleNext = () => {
		this.setState({ carouselIndex: this.state.carouselIndex + 1});
	}

	render() {

		const { classes } = this.props;

		var slider = null;
		
		slider = (
			<Carousel
				showIndicators={false}
				showStatus={false}
				showThumbs={false}
				autoPlay={true}
				infiniteLoop={true}
				interval={4000}
				showArrows={false}>

				<div className={classes.cf_main}>
					<div className={classes.cf_wrapper}>
						<Typography>
							<span className={classes.cf_text}>Our experience has been a very positive one. Working with a management team that is accommodating and sensitive to our needs. What a concept! Their professionalism shows in management, quality of work and customer service.</span>
							<span className={classes.cf_name}>- Swastik Group</span>
						</Typography>
					</div>
				</div>
				<div className={classes.cf_main}>
					<div className={classes.cf_wrapper}>
						<Typography>
						<span className={classes.cf_text}>I wanted to express our appreciation for the excellent services that received from the Easy Construction Services. The project was extremely successful and our personnel could not have been more pleased with their new work environment.</span>
						<span className={classes.cf_name}>- Bony Polymers Pvt Ltd</span>
						</Typography>
					</div>
				</div>
				<div className={classes.cf_main}>
					<div className={classes.cf_wrapper}>
						<Typography>
						<span className={classes.cf_text}>It has been a real pleasure for me to have a company like ECS (Easy Construction Services) to build our Bungalow. Your staff not only delivered a beautiful facility for our residents within the time allotted but also helped us value engineer this facility to help keep the cost within budget. This was done without sacrificing quality.</span>
						<span className={classes.cf_name}>- Dr. Dharmang Oza</span>
						</Typography>
					</div>
				</div>				
				<div className={classes.cf_main}>
					<div className={classes.cf_wrapper}>
						<Typography>
						<span className={classes.cf_text}>Our architect and several subs have specifically mentioned how much they enjoyed working with ECS. I was also very pleased with the timeliness of the construction. It was a pleasure to work with professionals that treated the project and jobsite as if it were their own property and building.</span>
						<span className={classes.cf_name}>- Gurudev Infrastructure Co.</span>
						</Typography>
					</div>
				</div>
				<div className={classes.cf_main}>
					<div className={classes.cf_wrapper}>
						<Typography>
						<span className={classes.cf_text}>We hired ECS on our Dining Hall Renovation Project at a point in the planning phase where I was very unsure whether the project could be successful given the extremely tight construction schedule we had to work with. ECS did an excellent job taking the time to understand our needs and working with our preferred subcontractors to deliver a project that not only met our scheduled deadline, but also at cost under our original budget.</span>
						<span className={classes.cf_name}>- Shopan Pratishthan</span>
						</Typography>
					</div>
				</div>
				
			</Carousel>
		);
					

		return (			
			<div ref={(ref) => this.customersFeedbackCarouselRef = ref} className="main-container home">
				<div style={{margin: "60px 0 70px 0", backgroundColor: "#fff"}}>
					<div className={classes.heading}>						
						<Grid container spacing={16}>
							<Grid item xs={12} sm={4}></Grid>
							<Grid item xs={12} sm={4}>
								<Typography variant="headline" align="center" style={{paddingBottom: "10px", fontWeight: "bold"}}>Customers Feedback</Typography>
							</Grid>
							<Grid item xs={12} sm={4}></Grid>
						</Grid>
					</div>
					<div style={{position: "relative", marginTop: "5px"}}>
						{slider}					
					</div>
				</div>
			</div>			
		);
	}
}

const styles = theme => ({
	heading: {
		margin: "25px 0 25px 0", 
		padding: "20px 30px 10px 30px",
		backgroundColor: "#efefef",
    	borderBottom: "1px dashed #777"
    },
	margin: {
	  margin: theme.spacing.unit,
	},
	cssRoot: {
	  color: theme.palette.getContrastText(grey[100]),
	  backgroundColor: grey[100],
	  '&:hover': {
		backgroundColor: "#F7D200",
	  },
	},	
	ccontent: {
		padding: theme.spacing.unit,
	},
	cmedia: {
		height: 140
	},	
	shadowcard: {
		
	},
	noshadowcard: {
		boxShadow: "none"
	},
	cf_main: {
		background: "#fff"
	},
	cf_wrapper: {

	},
	cf_text: {
		display: "block",
		textAlign: "left",
		color: "#777",
    	margin: "0",
		padding: "5px 20px 5px 20px",
		fontSize: "14px",		
	},
	cf_name: {
		display: "block",
		textAlign: "right",
		color: "#444",
		margin: "0",		
		padding: "5px 20px 30px 20px",
		fontSize: "16px",
	}
});

CustomersFeedback.propTypes = {
	classes: PropTypes.object.isRequired,
};
CustomersFeedback.contextTypes = {
    router: PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
	session: state.session,
	projectType: state.quotation.projectType,
});

export default withStyles(styles)(connect(mapStateToProps)(CustomersFeedback));